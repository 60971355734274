import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IStockChartData, IStocksData } from '../model/data.MODEL';

@Injectable({
  providedIn: 'root',
})
export class StockDataService {
  private stocksDataSubject: BehaviorSubject<IStocksData[]> =
    new BehaviorSubject<IStocksData[]>(null);

  public normalizedStocksData$: Observable<IStocksData[]> =
    this.stocksDataSubject.asObservable();

  private stockChartDataSubject: BehaviorSubject<IStockChartData> =
    new BehaviorSubject<IStockChartData>(null);

  public stockChartData$: Observable<IStockChartData> =
    this.stockChartDataSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private adalService: AdalService
  ) {}
  getTime(date: Date) {
    const hours =
      date.getHours() < 10 ? '0' + date.getHours() : date.getHours().toString();
    const minutes =
      date.getMinutes() < 10
        ? '0' + date.getMinutes()
        : date.getMinutes().toString();
    return `${hours}:${minutes}`;
  }

  public getStocks() {
    const url = environment.baseUri+environment.stocksAPIUrl;
    this.httpClient
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.adalService.userInfo.token}`,
        },
      })
      .pipe(
        map((stockArr: any) => {
          const newStockArr: IStocksData[] = stockArr.map((stock) => {
            return {
              stockId: stock.stockId,
              exchange: stock.exchange,
              stock: stock.stock,
              rate: +stock.LastKnownRate,
              dayChange: +stock.BaseRateChangePercentage,
              time: this.getTime(new Date(stock.LastKnownRateDate)),
            };
          });
          return newStockArr;
        })
      )
      .subscribe((data) => this.stocksDataSubject.next(data));
  }

  public setStockOnChart(stockId: number): Observable<IStockChartData> {
    const url = (environment.baseUri+environment.histogramStockAPIUrl).replace(
      '{{paperId}}',
      stockId.toString()
    );

    this.httpClient
      .get<IStockChartData>(url, {
        headers: {
          Authorization: `Bearer ${this.adalService.userInfo.token}`,
        },
      })
      .subscribe((data: IStockChartData) => {
        this.stockChartDataSubject.next(data);
      });
    return this.stockChartData$;
  }
}
