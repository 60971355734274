import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdalLoginService {
  private token: string = null;

  constructor(
    private adalService: AdalService,
    private http: HttpClient,
    private router: Router
  ) {
    this.adalService.init({...environment.adalConfig, redirectUri: window.location.origin + '/postauth'});
    this.adalService.handleWindowCallback();
  }

  login() {
    if (this.adalService.userInfo.authenticated) {
      this.getToken();
      return true;
    } else {
      this.adalService.login();
      localStorage.removeItem('pathPerfix');
      localStorage.removeItem('sso.idtoken');
    }
  }

  logOut() {
    this.adalService.logOut();
  }

  isLoggedIn() {
    if (localStorage.getItem('sso.idtoken') != null) return true;
    return this.adalService.userInfo.authenticated;
  }

  async getToken() {
    if (localStorage.getItem('sso.idtoken') != null) {
      this.token = localStorage.getItem('sso.idtoken');
      this.adalService.userInfo.token = this.token;
    } else if (this.adalService.userInfo.authenticated) {
      this.token = await this.adalService
        .acquireToken(this.adalService.config.loginResource)
        .toPromise();
      this.adalService.userInfo.token = this.token;
      localStorage.removeItem('pathPerfix');
      localStorage.removeItem('sso.idtoken');
    }
  }
  async getTokenValue() {
    if (!this.token) {
      await this.getToken();
    }
    return this.token;
  }
  async getRawData() {
    if (!this.token) {
      await this.getToken();
    }
    return await this.http
      .get<any>(environment.baseUri + environment.dataApiEndpoint, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .toPromise();
  }
}
