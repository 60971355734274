import { Component } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

const firebaseConfig = {
  apiKey: 'AIzaSyDXk4Zy8HRAlVi4IcdcA5yKKDI7RUCTJj4',
  authDomain: 'icl-id-rtba.firebaseapp.com',
  projectId: 'icl-id-rtba',
  storageBucket: 'icl-id-rtba.appspot.com',
  messagingSenderId: '915018253715',
  appId: '1:915018253715:web:9a95c0739110b7f6ba5466',
  measurementId: 'G-MVL2CH7E68',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

isSupported().then((result) => {
  console.log('analytics supported');
  logEvent(analytics, 'vibes_page_view');
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private route: ActivatedRoute) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('token');

    urlParams.has('token') ? localStorage.setItem('sso.idtoken', token) : '';
    urlParams.has('pathPerfix')
      ? localStorage.setItem('pathPerfix', urlParams.get('pathPerfix'))
      : '';

    localStorage.getItem('pathPerfix')
      ? (environment.baseUri =
          environment.baseUri + localStorage.getItem('pathPerfix') + '/')
      : '';
  }

  title = 'RTBA';
}
