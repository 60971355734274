export const environment = {
  production: true,
  baseUri: 'https://api.rtba1-dev.apps4.icldig.icl-group.com/',
  dataApiEndpoint: 'data/all',
  stocksAPIUrl: 'globes-api/getAllPapers',
  histogramStockAPIUrl: `globes-api/getPaperChart?paperid={{paperId}}`,
  adalConfig: {
    tenant: '802762d2-02c4-4677-98ba-54060a234204',
    clientId: '4331a39e-68e4-4ab8-b58b-66e0e9ad4168',
    expireOffsetSeconds: 320, //15 minutes (60-15 = 45 minutes auth)
    popUp: false,
    cacheLocation: 'localStorage',
    resource: 'https://graph.microsoft.com',
  },
};
