import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdalLoginService } from '../services/adal-login.service';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss'],
})
export class LoadingPageComponent implements OnInit {
  constructor(private loginService: AdalLoginService, private router: Router) {}

  isLoggedIn(): boolean {
    return this.loginService.isLoggedIn();
  }

  ngOnInit(): void {
    if (this.isLoggedIn()) {
      this.router.navigate(['dashboard']);
    } else {
      this.loginService.login();
    }
  }
}
