import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdalGuard } from 'adal-angular4';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardGuard } from './guards/dashboard.guard';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SplashComponent } from './splash/splash.component';

const routes: Routes = [
  { path: '', component: SplashComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [DashboardGuard],
  },
  {
    path: 'postauth',
    component: LoadingPageComponent,
  },
  { path: 'welcome', component: WelcomePageComponent },
  { path: 'terms', component: TermsOfUseComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
