export const environment = {
  production: false,
  baseUri: 'https://api.rtba1.apps4.icldig.icl-group.com/',
  dataApiEndpoint: 'data/all',
  stocksAPIUrl: 'globes-api/getAllPapers',
  histogramStockAPIUrl: `globes-api/getPaperChart?paperid={{paperId}}`,
  adalConfig: {
    tenant: '802762d2-02c4-4677-98ba-54060a234204',
    clientId: '1876aff9-7d03-40fe-9de0-cac7db2907b6',
    expireOffsetSeconds: 320, //15 minutes (60-15 = 45 minutes auth)
    popUp: false,
    cacheLocation: 'localStorage',
    resource: 'https://graph.microsoft.com',
  },
};
