import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-oi-by-sbu-section',
  templateUrl: './oi-by-sbu-section.component.html',
  styleUrls: ['./oi-by-sbu-section.component.scss']
})
export class OiBySbuSectionComponent implements OnInit {
  @Input() oiByBsuData!: any;

  public SBUSectors : string[] = ['IP', 'P', 'PS', 'GS'];
  constructor() { }

  ngOnInit(): void {}

}
