<div class="splash-container">
  <div class="mid-container">
    <img src="./assets/icl-logo.svg" class="icl-logo" alt="icl-logo" />
    <h1>Welcome to</h1>
    <img src="./assets/vibes-logo.png" class="rtba-logo" alt="rtba-logo" />
  </div>
  <div class="bottom-container">
    <button (click)="login()">Launch the experience</button>
  </div>
</div>

<div class="version">Version: 1.3.6</div>
